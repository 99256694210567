export type VersionInfo = {
    version: string
    license: {
        variant: string
        expires: string
        to: string
    }

}

export function defaultVersionInfo(): VersionInfo {
    return {
        version: 'unknown',
        license: {
            variant: 'unknown',
            expires: 'unknown',
            to: 'unknown',
        },
    }
}
